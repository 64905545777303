@import "ui/src/themes/colors";

.noBackground {
  background: transparent;
  transition: background-color 0.25s ease;
}

.showBackground {
  background: #DCF9F4;
  transition: background-color 0.25s ease;
}

.text {
  color: #387765;
  font-size: 16px;
  font-weight: bold;
  margin-left: 14px;
}

.textContainer {
  align-items: center;
  display: flex;
}

.uploadContainer {
  align-items: center;
  border-radius: 4px;
  border: 1px dashed $euiColorSuccess;
  display: flex;
  height: 88px;
  justify-content: center
}
